import React from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import css from './resetPasswordForm.module.css';

const ResetPasswordForm = ({ handleSubmit }) => {
  const validationSchema = yup.object().shape({
    currentPassword: yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?!.*\s)/,
        'Use at least one: (0-9), (a-z), (A-Z), (@#$%^&+=!)'
      )
      .max(8, 'Password must be at most 8 characters long'),

    newPassword: yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?!.*\s)/,
        'Use at least one: (0-9), (a-z), (A-Z), (@#$%^&+=!)'
      )
      .max(8, 'Password must be at most 8 characters long'),

    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match').required('Confirm new password is required'),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount={true} 
    >
      {({ errors, touched, isValid }) => (
        <Form className={css.registerForm}>
          <div>
            <label htmlFor="currentPassword" className={css.text}>
              Current Password
            </label>
            <Field
              className={`${touched.currentPassword && errors.currentPassword ? css.isInvalid : touched.currentPassword ? css.isValid : ''} ${css.input}`}
              type="password"
              id="currentPassword"
              name="currentPassword"
              autoComplete="new-password"
              placeholder="Enter current password"
            />
            <ErrorMessage name="currentPassword" component="div" className={css.errorMessage} />
          </div>
          <div>
            <label htmlFor="newPassword" className={css.text}>
              New Password
            </label>
            <Field
              className={`${touched.newPassword && errors.newPassword ? css.isInvalid : touched.newPassword ? css.isValid : ''} ${css.input}`}
              type="password"
              id="newPassword"
              name="newPassword"
              autoComplete="new-password"
              placeholder="Enter new password"
            />
            <ErrorMessage name="newPassword" component="div" className={css.errorMessage} />
          </div>
          <div className={css.buttonSubmit}>
          <div>
            <label htmlFor="confirmNewPassword" className={css.text}>
              Confirm New Password
            </label>
            <Field
              className={`${touched.confirmNewPassword && errors.confirmNewPassword ? css.isInvalid : touched.confirmNewPassword ? css.isValid : ''} ${css.input}`}
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              autoComplete="new-password"
              placeholder="Confirm new password"
            />
            <ErrorMessage name="confirmNewPassword" component="div" className={css.errorMessage} />
          </div>
          {isValid && (
              <button
                className={css.button}
                type="submit"
              >
                UPDATE PASSWORD
              </button>
          )}
            </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
