import { useAuth } from 'components/LoginForm/AuthContext';
import { useEffect } from 'react';
import { useMessages } from './MessageContext';
import { useNotification } from './NotificationContext';

const SocketNotification = () => {
  const { messages, setMessages } = useMessages();
  const { setNotification } = useNotification();
  const { logout } = useAuth();
  const token = localStorage.getItem('access_token');
  const socketUrl = `wss://relativelys.eu/notification?token=${token}`;

  useEffect(() => {
    if (!token) {
      return;
    }
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      socket.send('1');
      console.log('WebSocket connection established.');
    };

    socket.onmessage = event => {
      socket.send('1');
      try {
        const data = JSON.parse(event.data);
        console.log('Message from server: ', data);

        if (data.logout) {
          console.log('Logout message received. Logging out user.');
          setNotification('Password has been reset successfully.');
          logout();
        } else if (data.new_message) {
          setMessages(data.new_message);
          console.log(data.new_message);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    socket.onerror = error => {
      console.error('WebSocket error: ', error);
    };

    socket.onclose = event => {
      console.log('WebSocket connection closed: ', event);
    };

    const sendNumber = () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send('1');
        console.log('Sent number 1 to server');
      }
    };

    const interval = setInterval(sendNumber, 5000);

    return () => {
      clearInterval(interval);
      socket.close();
    };
  }, [socketUrl, logout, setNotification]);

  return (
    // <div style={{ display: 'none' }}>

    // <div >
    //  {messages.map((message, index) => (
    //     <div key={index}>{message}</div>
    //   ))}
    // </div>
    <div style={{ display: 'none' }}>
      {messages.map((message, index) => (
        <div key={index}>{message.message}</div>
      ))}
    </div>
  );
};

export default SocketNotification;
