import React, { useState, useEffect } from 'react';
import CustomModal from './CustomModal';
import css from './DeleteAccountModal.module.css';

const DeleteAccountModal = ({
  isOpen,
  onClose,
  onConfirmDelete,
 }) => {

    const [password, setPassword] = useState('');

    useEffect(() => {
      if (isOpen) {
        setPassword('');
        console.log("done");
      }
    }, [isOpen]);

    const handleConfirmDelete = () => {
        if (password) {
        onConfirmDelete(password);
        } else {
        alert('Please enter your password.');
        }
    };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
    <div className={css.createRoomContainer}>
      <h2 className={css.title}>Are you sure you want to delete your account?</h2>
      <input
          type="password"
          placeholder="Enter your password"
          value={password}
          autoComplete="new-password"
          onChange={(e) => setPassword(e.target.value)}
          className={css.passwordInput}
        />
      <div className={css.center}>
        <button className={css.cancelButton} onClick={onClose} >
        Cancel
        </button>
        <button className={css.confirmButton} onClick={handleConfirmDelete}>
            Delete account
          </button>
      </div>
    </div>
  </CustomModal>
);
};

export default DeleteAccountModal;