import axios from 'axios';
import { useState } from 'react';
import css from './CreateTabModal.module.css';
import CustomModal from './CustomModal';

const ChangeIconTabModal = ({
  isOpen,
  onClose,
  authToken,
  currentTabId,
  icons,
  setTabs,
  tabs,
}) => {
  const [selectedIcon, setSelectedIcon] = useState('');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChangeIcon = async () => {
    try {
      const nameTab = tabs.find(tab => tab.id === currentTabId)?.name_tab;

      const requestData = {
        name_tab: nameTab,
        image_tab: selectedIcon,
      };

      console.log(requestData);

      const response = await axios.put(
        `${apiBaseUrl}/api/tabs/?id=${currentTabId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      console.log('Tab icon updated:', response.data);
      onClose();
      setTabs(prevTabs =>
        prevTabs.map(tab =>
          tab.id === currentTabId ? { ...tab, image_tab: selectedIcon } : tab
        )
      );
    } catch (error) {
      console.error('Error updating tab icon:', error);
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
      <div className={css.createRoomContainer}>
        <h2 className={css.title}>Choose the icon</h2>
        <div className={css.iconContainer}>
          {Object.keys(icons).map(iconName => {
            const IconComponent = icons[iconName].component;
            return (
              <div
                key={iconName}
                className={`${css.iconWrapper} ${
                  selectedIcon === iconName ? css.selected : ''
                }`}
                onClick={() => setSelectedIcon(iconName)}
              >
                <IconComponent />
                {/* <span>{icons[iconName].name}</span>{' '} */}
              </div>
            );
          })}
        </div>
        <div className={css.center}>
          <button className={css.button} onClick={handleChangeIcon}>
            Approve
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ChangeIconTabModal;
