import imgModalSuccess from '../Images/imgModalSuccess.png';
import CustomModal from './CustomModal';
import css from './DeleteRoomFromTabModal.module.css';

const AddRoomToTabModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
      <div className={css.createRoomContainer}>
        <h2 className={css.title}>Rooms added to the tab</h2>
        <img className={css.imgCenter} src={imgModalSuccess} alt="add rooms" />
        <div className={css.center}>
          <button className={css.cancelButton} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddRoomToTabModal;
