import axios from 'axios';
import { ReactComponent as AddFileSVG } from 'components/Images/AddFileSVG.svg';
import { ReactComponent as AnimatesTypingSVG } from 'components/Images/animatedWrite.svg';
import { ReactComponent as ButtonReplyCloseSVG } from 'components/Images/ButtonReplyClose.svg';
import { ReactComponent as ButtonSendMessage } from 'components/Images/ButtonSendMessage.svg';
import { ReactComponent as DeleteSvg } from 'components/Images/Delete.svg';
import { ReactComponent as EditSvg } from 'components/Images/Edit.svg';
import { ReactComponent as IconReplySVG } from 'components/Images/IconReply.svg';
import { ReactComponent as LikeSVG } from 'components/Images/Like.svg';
import { ReactComponent as ReplySvg } from 'components/Images/Reply.svg';
import { ReactComponent as SendImgSVG } from 'components/Images/SendImg.svg';
import { ReactComponent as ShowTypingSVG } from 'components/Images/userWrite.svg';
import ImageModal from 'components/Modal/ImageModal';
import { format, isToday, isYesterday } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import css from '../Chat/Chat.module.css';
import Bg from '../Images/Bg_empty_chat.png';

const PersonalChat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [selectedReplyMessageId, setSelectedReplyMessageId] = useState(null);
  const [selectedReplyMessageText, setSelectedReplyMessageText] =
    useState(null);
  const [selectedReplyMessageImage, setselectedReplyMessageImage] =
    useState(null);
  const [selectedReplyMessageSender, setSelectedReplyMessageSender] =
    useState(null);
  const [imageText, setImageText] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedMessage, setEditedMessage] = useState('');
  const [isImageSending, setIsImageSending] = useState(false);
  const [isChatMenuOpen, setIsChatMenuOpen] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const lastLikedMessageIdRef = useRef(null);
  const [showSVG, setShowSVG] = useState(false);
  const [partnerId, setPartnerId] = useState(null);

  const messageRefs = useRef({});

  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [limit, setLimit] = useState(50);
  // const [loadingMessageIds, setLoadingMessageIds] = useState(new Set());

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const currentUserId = localStorage.getItem('user_id');

  const token = localStorage.getItem('access_token');
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const recipientId = localStorage.getItem('currentPartnerId');
    setPartnerId(recipientId);
  }, []);

  const socketRef = useRef(null);

  useEffect(() => {
    if (!partnerId) return;
    console.log(partnerId);

    const socket = new WebSocket(
      `wss://relativelys.eu/private/${partnerId}?token=${token}`
    );
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('Connected to the server via WebSocket');
    };

    let isAnimating = false;

    socket.onmessage = async event => {
      const container = messageContainerRef.current;
      const currentScrollHeight = container.scrollHeight;
      const currentScrollTop = container.scrollTop;
      try {
        const messageData = JSON.parse(event.data);
        console.log('Received message:', messageData);

        if (messageData.notice === 'Load older messages') {
          setHasMoreMessages(true);
          // console.log('Есть ещё старые сообщения.');
        }
        if (messageData.notice === 'Loading all messages') {
          setHasMoreMessages(false);
          // console.log('Все сообщения загружены.');
          return;
        }
        if (messageData.deleted) {
          const messageId = messageData.deleted.id;
          console.log(messageId);

          setMessages(prevMessages => {
            return prevMessages.map(msg => {
              if (msg.id === messageId) {
                return {
                  ...msg,
                  message: null,
                  fileUrl: null,
                  deleted: true,
                };
              }
              return msg;
            });
          });
        }

        if (messageData.update) {
          const updatedMessage = {
            ...messageData.update,
            timestamp: new Date(messageData.update.created_at).getTime(),
          };

          setMessages(prevMessages => {
            const existingMessageIndex = prevMessages.findIndex(
              msg => msg.id === updatedMessage.id
            );

            if (existingMessageIndex !== -1) {
              // Заменяем старое сообщение обновленным
              const updatedMessages = [...prevMessages];
              updatedMessages[existingMessageIndex] = updatedMessage;
              return updatedMessages;
            }

            // Если по какой-то причине не найдено (добавляем как новое, что редко будет нужно)
            return [...prevMessages, updatedMessage];
          });
        } else if (messageData.type && !isAnimating) {
          console.log('1234:', messageData.type);
          isAnimating = true;
          setShowSVG(true);

          setTimeout(() => {
            setShowSVG(false);
            isAnimating = false;
          }, 3000);
        } else if (messageData.message) {
          // } else if (messageData.message.id || messageData.message.id === 0) {
          const {
            user_name: sender = 'Unknown Sender',
            receiver_id,
            created_at,
            avatar,
            deleted,
            message,
            id,
            id_return,
            vote,
            fileUrl,
            edited,
            verified,
          } = messageData.message;
          const formattedDate = formatTime(created_at);

          const newMessage = {
            sender,
            avatar,
            message,
            id,
            id_return,
            vote,
            formattedDate,
            receiver_id,
            fileUrl,
            edited,
            verified,
            deleted,
            timestamp: new Date(created_at).getTime(),
          };

          setMessages(prevMessages => {
            const existingMessageIndex = prevMessages.findIndex(
              msg => msg.id === newMessage.id
            );

            if (existingMessageIndex !== -1) {
              const updatedMessages = [...prevMessages];
              updatedMessages[existingMessageIndex] = newMessage;
              // Сортировка сообщений
              updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
              return updatedMessages;
            }

            const updatedMessages = [...prevMessages, newMessage];
            // Сортировка сообщений
            updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
            return updatedMessages;
          });
        } else if (messageData.messages) {
          const newMessages = messageData.messages.map(msg => ({
            sender: msg.user_name || 'Unknown Sender',
            avatar: msg.avatar,
            message: msg.message,
            id: msg.id,
            id_return: msg.id_return,
            formattedDate: formatTime(msg.created_at),
            receiver_id: msg.receiver_id,
            fileUrl: msg.fileUrl,
            edited: msg.edited,
            verified: msg.verified,
            deleted: msg.deleted,
            timestamp: new Date(msg.created_at).getTime(),
          }));

          setMessages(prevMessages => {
            const updatedMessages = [...newMessages.reverse(), ...prevMessages];
            // Сортировка сообщений
            updatedMessages.sort((a, b) => a.timestamp - b.timestamp);
            return updatedMessages;
          });
        }
        setShouldScrollToBottom(false);
        // Обновление прокрутки
        setTimeout(() => {
          container.scrollTop =
            container.scrollHeight - currentScrollHeight + currentScrollTop;
        }, 0);
        setShouldScrollToBottom(false);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    socket.onerror = error => {
      console.error('WebSocket Error:', error);
    };

    return () => {
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.close();
      }
    };
  }, [partnerId, token]);

  useEffect(() => {
    if (shouldScrollToBottom && messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
      const timeoutId = setTimeout(() => {
        setShouldScrollToBottom(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldScrollToBottom, messages]);

  const sendMessage = async () => {
    const trimmedMessage = message.trim();
    if (!trimmedMessage) {
      console.log('No message to send.');
      console.log(trimmedMessage);
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageObject = {
        send: {
          original_message_id: selectedReplyMessageId || null,
          message: trimmedMessage || null,
          fileUrl: null,
        },
      };

      console.log(messageObject);

      const messageString = JSON.stringify(messageObject);
      socketRef.current.send(messageString);
      setShouldScrollToBottom(true);
      setMessage('');
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  };

  const handleMessageChange = e => {
    setMessage(e.target.value);
    setEditedMessage(e.target.value);
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ type: 'typing' }));
    }
  };

  const formatTime = created => {
    if (!created || isNaN(new Date(created))) {
      return '';
    }
    const dateTime = new Date(created);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (isToday(dateTime)) {
      return format(dateTime, 'HH:mm');
    } else if (isYesterday(dateTime)) {
      return `yesterday ${format(dateTime, 'HH:mm')}`;
    } else {
      return format(dateTime, 'dd MMM HH:mm');
    }
  };

  const handleLikeClick = id => {
    const requestData = {
      vote: {
        message_id: id,
        dir: 1,
      },
    };

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageString = JSON.stringify(requestData);
      socketRef.current.send(messageString);
      lastLikedMessageIdRef.current = id;
      console.log('Liked message ID:', lastLikedMessageIdRef.current);
      setShouldScrollToBottom(false);
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  };

  const handleCloseChatMenu = () => {
    setIsChatMenuOpen(false);
  };

  const handleCloseReply = () => {
    setSelectedReplyMessageId(null);
  };

  const transliterateAndSanitize = fileName => {
    const transliterationMap = {
      А: 'A',
      Б: 'B',
      В: 'V',
      Г: 'G',
      Д: 'D',
      Е: 'E',
      Ё: 'E',
      Ж: 'Zh',
      З: 'Z',
      И: 'I',
      Й: 'Y',
      К: 'K',
      Л: 'L',
      М: 'M',
      Н: 'N',
      О: 'O',
      П: 'P',
      Р: 'R',
      С: 'S',
      Т: 'T',
      У: 'U',
      Ф: 'F',
      Х: 'Kh',
      Ц: 'Ts',
      Ч: 'Ch',
      Ш: 'Sh',
      Щ: 'Shch',
      Ы: 'Y',
      Э: 'E',
      Ю: 'Yu',
      Я: 'Ya',
      Ї: 'Yi',
      І: 'I',
      Є: 'Ye',
      Ґ: 'G',
      а: 'a',
      б: 'b',
      в: 'v',
      г: 'g',
      д: 'd',
      е: 'e',
      ё: 'e',
      ж: 'zh',
      з: 'z',
      и: 'i',
      й: 'y',
      к: 'k',
      л: 'l',
      м: 'm',
      н: 'n',
      о: 'o',
      п: 'p',
      р: 'r',
      с: 's',
      т: 't',
      у: 'u',
      ф: 'f',
      х: 'kh',
      ц: 'ts',
      ч: 'ch',
      ш: 'sh',
      щ: 'shch',
      ы: 'y',
      э: 'e',
      ю: 'yu',
      я: 'ya',
      ї: 'yi',
      і: 'i',
      є: 'ye',
      ґ: 'g',
    };

    let transliteratedFileName = fileName
      .split('')
      .map(char => {
        if (transliterationMap[char]) {
          return transliterationMap[char];
        } else if (char === ' ') {
          return '_';
        } else if (/^[a-zA-Z0-9\-_.]$/.test(char)) {
          return char;
        } else {
          return '';
        }
      })
      .join('');

    return transliteratedFileName;
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      const sanitizedFileName = transliterateAndSanitize(selectedImage.name);
      const file = new File([selectedImage], sanitizedFileName, {
        type: selectedImage.type,
      });
      formData.append('file', file);

      console.log(file);

      // const response = await axios.post('https://cool-chat.club/api/upload_google/uploadfile/', formData);
      const response = await axios.post(
        `${apiBaseUrl}/api/upload-to-backblaze/chat?bucket_name=chatall`,
        formData
      );

      if (response && response.data) {
        const imageUrl = response.data;
        // const imageUrl = response.data.public_url;
        setSelectedImage(imageUrl);
        console.log(imageUrl);
        return imageUrl;
      } else {
        console.error('Failed to upload image');
        return null;
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleImageSend = async () => {
    if (!selectedImage) {
      console.error('No image selected.');
      return;
    }
    if (isImageSending) {
      console.log('Image is already being sent.');
      return;
    }

    setIsImageSending(true);

    try {
      const imageUrl = await uploadImage(selectedImage);

      if (!imageUrl) {
        console.error('Failed to upload image.');
        return;
      }

      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        const messageObject = {
          send: {
            original_message_id: null,
            message: imageText || null,
            fileUrl: imageUrl,
          },
        };

        const messageString = JSON.stringify(messageObject);
        socketRef.current.send(messageString);

        setSelectedImage(null);
        setSelectedFilesCount(0);
        setImageText('');
      } else {
        console.error('WebSocket is not open. Message not sent.');
      }
    } catch (error) {
      console.error('Error sending image:', error);
    } finally {
      setIsImageSending(false);
    }
  };

  const handleImageChange = event => {
    const files = event.target.files;
    setSelectedFilesCount(files.length);
    const file = files[0];
    if (file) {
      if (file.size <= 15 * 1024 * 1024) {
        setSelectedFilesCount(files.length);
        setSelectedImage(file);
      } else {
        alert('Selected file is too large. Please select a file up to 5 MB.');
        setSelectedFilesCount(0);
      }
    }
  };

  const handleImageClose = () => {
    setSelectedImage(null);
    setSelectedFilesCount(0);
  };

  const handleMouseEnter = id => {
    setHoveredMessageId(id);
  };

  const handleMouseLeave = () => {
    setHoveredMessageId(null);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (editingMessageId) {
        handleEditMessageSend();
      } else {
        handleChatMessageSend();
      }
    }
  };

  const handleSelectReplyMessage = (
    messageId,
    messageText,
    messageSender,
    imageUrl
  ) => {
    setSelectedReplyMessageId(messageId);
    setSelectedReplyMessageText(messageText);
    setSelectedReplyMessageSender(messageSender);
    setselectedReplyMessageImage(imageUrl);
  };

  const handleSendReply = async replyMessage => {
    console.log(replyMessage);

    let fileUrl = null;

    if (selectedImage) {
      fileUrl = await uploadImage(selectedImage);
      console.log(fileUrl);
      if (!fileUrl) {
        console.error('Failed to upload file.');
        return;
      }
    }

    if (!replyMessage.trim() && !fileUrl) {
      console.log('Both reply message and file are empty. Not sending reply.');
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const replyData = {
        send: {
          original_message_id: selectedReplyMessageId,
          message: replyMessage,
          fileUrl: fileUrl,
        },
      };
      console.log('Preparing to send reply:', replyData);

      const messageString = JSON.stringify(replyData);
      socketRef.current.send(messageString);
      console.log('Reply successfully sent.');
      setSelectedImage(null);
      setSelectedFilesCount(0);
      setImageText('');
    } else {
      console.error('WebSocket is not open. Reply message not sent.');
    }
  };

  const handleChatMessageSend = () => {
    // if (editingMessageId) {
    //   handleEditMessageSend(editedMessage, editingMessageId);
    // }
    if (selectedReplyMessageId) {
      handleSendReply(message);
      setSelectedReplyMessageId(null);
      setSelectedReplyMessageText(null);
    } else {
      sendMessage();
    }
    setMessage('');
  };

  const handleDeleteMessage = messageId => {
    const deleteMessageObject = {
      delete: {
        id: messageId,
      },
    };

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const deleteMessageString = JSON.stringify(deleteMessageObject);
      socketRef.current.send(deleteMessageString);
    } else {
      console.error('WebSocket is not open. Delete message not sent.');
    }
    // setMessages(prevMessages =>
    //   prevMessages.filter(msg => msg.id !== messageId)
    // );
  };

  const handleEditMessageClick = (editedMsg, messageId) => {
    console.log(editedMessage, messageId);
    setEditingMessageId(messageId);
    setEditedMessage(editedMsg);
  };

  const handleEditMessageSend = () => {
    if (!editedMessage.trim()) {
      console.log('Edited message is empty. Not sending edit.');
      return;
    }

    const editMessageObject = {
      update: {
        id: editingMessageId,
        message: editedMessage,
      },
    };

    console.log(editMessageObject);

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const editMessageString = JSON.stringify(editMessageObject);
      socketRef.current.send(editMessageString);
    } else {
      console.error('WebSocket is not open. Edit message not sent.');
      return;
    }

    setEditingMessageId(null);
    setEditedMessage('');
    setMessage('');
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditedMessage('');
  };

  const getFileType = fileUrl => {
    const extension = getFileExtension(fileUrl).toLowerCase();
    // console.log('File extension:', extension);

    if (isImageExtension(extension)) {
      return 'image';
    } else if (isVideoExtension(extension)) {
      return 'video';
    } else if (isDocumentExtension(extension)) {
      return 'document';
    } else {
      return { extension };
    }
  };

  const getFileExtension = fileUrl => {
    const urlWithoutLastCharacter = fileUrl.endsWith('?')
      ? fileUrl.slice(0, -1)
      : fileUrl;
    return urlWithoutLastCharacter.split('.').pop();
  };

  const isImageExtension = extension => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    return imageExtensions.includes(extension);
  };

  const isVideoExtension = extension => {
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    return videoExtensions.includes(extension);
  };

  const isDocumentExtension = extension => {
    const documentExtensions = [
      'pdf',
      'doc',
      'docx',
      'ppt',
      'pptx',
      'xls',
      'xlsx',
    ];
    return documentExtensions.includes(extension);
  };

  const extractFileNameFromUrl = fileUrl => {
    let fileName = fileUrl.split('/').pop();
    if (fileName.endsWith('?')) {
      fileName = fileName.slice(0, -1);
    }
    return fileName.split('?')[0];
  };

  const renderFile = fileUrl => {
    const fileType = getFileType(fileUrl);

    switch (fileType) {
      case 'image':
        return (
          <img
            src={fileUrl}
            alt="Uploaded"
            className={css.imageInChat}
            onClick={e => {
              e.stopPropagation();
              setIsImageModalOpen(true);
              setSelectedImageUrl(fileUrl);
            }}
          />
        );
      case 'video':
        return (
          <video
            className={css.imageInChat}
            onClick={e => {
              e.stopPropagation();
            }}
            controls
          >
            <source src={fileUrl} type={`video/${getFileExtension(fileUrl)}`} />
          </video>
        );
      case 'document':
        const fileName = extractFileNameFromUrl(fileUrl);
        return (
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <svg
              width="36"
              height="48"
              className={css.docInChat}
              viewBox="0 0 36 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
            </svg>
            <p>{fileName}</p>Ч{' '}
          </a>
        );
      default:
        return <p>{fileUrl}</p>;
    }
  };

  const loadMoreMessages = () => {
    setLimit(prevLimit => {
      const newLimit = prevLimit + 50;

      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.send(
          JSON.stringify({
            limit: newLimit,
          })
        );
      }
      // console.log(newLimit);

      return newLimit;
    });
  };

  const handleScroll = () => {
    const container = messageContainerRef.current;

    if (container.scrollTop === 0 && hasMoreMessages) {
      loadMoreMessages();
    }
  };

  useEffect(() => {
    const container = messageContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMoreMessages]);

  const scrollToMessage = id => {
    const messageElement = messageRefs.current[id];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  useEffect(() => {
    // При изменении selectedReplyMessageId автоматически прокручиваем к нужному сообщению
    if (selectedReplyMessageId) {
      scrollToMessage(selectedReplyMessageId);
    }
  }, [selectedReplyMessageId]);

  return (
    <div className={css.container}>
      <h2 className={css.title}>Direct chat:</h2>
      <div className={css.main_container}>
        <div className={css.chat_container}>
          <div className={css.chat_area} ref={messageContainerRef}>
            {messages.length === 0 && (
              <div className={css.no_messages}>
                <img
                  src={Bg}
                  alt="No messages"
                  className={css.no_messagesImg}
                />
                <p className={css.no_messages_text}>
                  Oops... There are no messages here yet. Write first!
                </p>
              </div>
            )}
            {messages.map((msg, index) => (
              <div
                key={index}
                ref={el => (messageRefs.current[msg.id] = el)}
                className={`${css.chat_message} ${
                  parseInt(currentUserId) === parseInt(msg.receiver_id)
                    ? css.my_message
                    : ''
                }`}
              >
                <div
                  className={css.chat}
                  onMouseEnter={() => handleMouseEnter(msg.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={msg.avatar}
                    alt={`${msg.sender}'s Avatar`}
                    className={css.chat_avatar}
                  />
                  <div className={css.chat_div}>
                    <div className={css.chat_nicktime}>
                      <span className={css.chat_sender}>{msg.sender}</span>
                      <span className={css.time}>{msg.formattedDate}</span>
                    </div>
                    {msg.deleted ? (
                      <div
                        className={`${css.messageText} ${
                          parseInt(currentUserId) === parseInt(msg.receiver_id)
                            ? css.my_message_text
                            : ''
                        }`}
                      >
                        <i>Message deleted</i>
                      </div>
                    ) : msg.message || msg.fileUrl ? (
                      <div
                        className={`${css.messageText} ${
                          parseInt(currentUserId) === parseInt(msg.receiver_id)
                            ? css.my_message_text
                            : ''
                        }`}
                        onClick={() => setIsChatMenuOpen(msg.id)}
                      >
                        {msg.id_return && msg.id_return !== 0 ? (
                          messages.find(
                            message => message.id === msg.id_return
                          ) ? (
                            messages.map((message, index) => {
                              if (message.id === msg.id_return) {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => setIsChatMenuOpen(msg.id)}
                                  >
                                    <p className={css.replyMessageUsername}>
                                      {message.sender}
                                    </p>
                                    <div className={css.replyContentUp}>
                                      {message.fileUrl &&
                                        getFileType(message.fileUrl) ===
                                          'image' && (
                                          <img
                                            src={message.fileUrl}
                                            alt="Reply"
                                            className={css.ReplyMessageImage}
                                            onClick={e => {
                                              e.stopPropagation();
                                              setIsImageModalOpen(true);
                                              setSelectedImageUrl(
                                                message.fileUrl
                                              );
                                            }}
                                          />
                                        )}
                                      {message.fileUrl &&
                                        getFileType(message.fileUrl) ===
                                          'document' && (
                                          <a
                                            href={message.fileUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <svg
                                              width="36"
                                              height="48"
                                              className={css.docInChat}
                                              viewBox="0 0 36 48"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
                                            </svg>
                                          </a>
                                        )}
                                      {message.fileUrl &&
                                        getFileType(message.fileUrl) ===
                                          'video' && (
                                          <video
                                            className={css.imageInChat}
                                            controls
                                          >
                                            <source
                                              src={message.fileUrl}
                                              type={`video/${getFileExtension(
                                                message.fileUrl
                                              )}`}
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        )}
                                      {message.message && (
                                        <p className={css.replyMessageText}>
                                          {message.message}
                                        </p>
                                      )}
                                    </div>
                                    <div className={css.replyContentDown}>
                                      {msg.fileUrl && (
                                        <img
                                          src={msg.fileUrl}
                                          alt="Reply"
                                          className={css.ReplyMessageImage}
                                          onClick={e => {
                                            e.stopPropagation();
                                            setIsImageModalOpen(true);
                                            setSelectedImageUrl(msg.fileUrl);
                                          }}
                                        />
                                      )}
                                      <p className={css.messageTextReply}>
                                        {msg.message}
                                      </p>
                                      {msg.edited && (
                                        <span className={css.editedText}>
                                          edited
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <div
                              key={index}
                              onClick={() => setIsChatMenuOpen(msg.id)}
                            >
                              <p className={css.replyMessageUsername}>
                                {message.sender}
                              </p>
                              <div className={css.replyContentUp}>
                                {message.fileUrl && (
                                  <img
                                    src={message.fileUrl}
                                    alt="Reply"
                                    className={css.ReplyMessageImage}
                                  />
                                )}
                                <p className={css.replyMessageText}>
                                  Deleted Message
                                </p>
                              </div>
                              <div className={css.replyContentDown}>
                                {msg.fileUrl && (
                                  <img
                                    src={msg.fileUrl}
                                    alt="Reply"
                                    className={css.ReplyMessageImage}
                                  />
                                )}
                                <p className={css.messageTextReply}>
                                  {msg.message}
                                </p>
                                {msg.edited && (
                                  <span className={css.editedText}>edited</span>
                                )}
                              </div>
                            </div>
                          )
                        ) : (
                          <div>
                            {msg.fileUrl && renderFile(msg.fileUrl)}
                            {msg.message && <p>{msg.message}</p>}
                            {msg.edited && (
                              <span className={css.editedText}>edited</span>
                            )}
                          </div>
                        )}
                      </div>
                    ) : null}

                    <div className={css.actions}>
                      {!msg.deleted &&
                        (msg.vote > 0 || hoveredMessageId === msg.id) && (
                          <div
                            className={css.likeContainer}
                            onClick={() => handleLikeClick(msg.id)}
                          >
                            <LikeSVG className={css.like} />
                            {msg.vote !== 0 && <span>{msg.vote}</span>}
                          </div>
                        )}
                    </div>
                  </div>

                  {isChatMenuOpen === msg.id && (
                    <div
                      id={`chat-menu-container-${msg.id}`}
                      className={css.chatMenuContainer}
                    >
                      {parseInt(currentUserId) ===
                        parseInt(msg.receiver_id) && (
                        <div className={css.chatMenuList}>
                          <button
                            className={css.chatMenuMsgButton}
                            onClick={() => {
                              handleSelectReplyMessage(
                                msg.id,
                                msg.message,
                                msg.sender,
                                msg.fileUrl
                              );
                              handleCloseChatMenu();
                            }}
                          >
                            <ReplySvg />
                            Reply
                          </button>
                          <button
                            className={css.chatMenuMsgButton}
                            onClick={() => {
                              handleEditMessageClick(msg.message, msg.id);
                              handleCloseChatMenu();
                            }}
                          >
                            <EditSvg />
                            Edit
                          </button>
                          <button
                            className={css.chatMenuMsgButton}
                            onClick={() => {
                              handleDeleteMessage(msg.id);
                              handleCloseChatMenu();
                            }}
                          >
                            <DeleteSvg />
                            Delete
                          </button>
                        </div>
                      )}
                      {parseInt(currentUserId) !==
                        parseInt(msg.receiver_id) && (
                        <div className={css.chatMenuList}>
                          <button
                            className={css.chatMenuMsgButton}
                            onClick={() => {
                              handleSelectReplyMessage(
                                msg.id,
                                msg.message,
                                msg.sender,
                                msg.fileUrl
                              );
                              handleCloseChatMenu();
                            }}
                          >
                            <ReplySvg />
                            Reply
                          </button>
                        </div>
                      )}
                      <button
                        className={css.chatMenuClose}
                        onClick={handleCloseChatMenu}
                      >
                        X
                      </button>
                    </div>
                  )}
                  {showSVG && (
                    <div className={css.svg_container}>
                      <AnimatesTypingSVG className={css.wave} />
                      <ShowTypingSVG />
                    </div>
                  )}
                </div>
              </div>
            ))}

            {/* {selectedReplyMessageId && (
              <div className={css.replyContainer}>
                <IconReplySVG/>
                <div className={css.replyContainerFlex}>
                  <p className={css.replyMessageUsername}>Reply to {selectedReplyMessageSender}</p>
                  <div className={css.replyContainerImgText}>
                  {selectedReplyMessageImage && (
                    <div>
                      <img src={selectedReplyMessageImage} alt="Reply" className={css.replyImage} />
                    </div>
                  )}
                  {selectedReplyMessageText && (
                    <p className={css.chatTextReply}>{selectedReplyMessageText}</p>
                  )}
                  </div>
                </div>
                <div className={css.buttons}>
                  <ButtonReplyCloseSVG onClick={handleCloseReply} className={css.svgCloseReply}/>
                </div>
              </div>
            )} */}
          </div>
          {selectedReplyMessageId && (
            <div className={css.replyContainer}>
              <IconReplySVG />
              <div className={css.replyContainerFlex}>
                <p className={css.replyMessageUsername}>
                  Reply to {selectedReplyMessageSender}
                </p>
                <div className={css.replyContainerImgText}>
                  {selectedReplyMessageImage && (
                    <div>
                      <img
                        src={selectedReplyMessageImage}
                        alt="Reply"
                        className={css.replyImage}
                      />
                    </div>
                  )}

                  {selectedReplyMessageText && (
                    <p className={css.chatTextReply}>
                      {selectedReplyMessageText}
                    </p>
                  )}
                </div>
              </div>
              <div className={css.buttons}>
                <ButtonReplyCloseSVG
                  onClick={handleCloseReply}
                  className={css.svgCloseReply}
                />
              </div>
            </div>
          )}
          {selectedImage && (
            <div className={css.imgContainerUpload}>
              <div className={css.imgUploadDiv}>
                {isImageExtension(
                  selectedImage.name.toLowerCase().split('.').pop()
                ) ? (
                  <img
                    className={css.imgUpload}
                    src={URL.createObjectURL(selectedImage)}
                    alt={`Preview`}
                    onClick={() => {
                      setIsImageModalOpen(true);
                      setSelectedImageUrl(URL.createObjectURL(selectedImage));
                    }}
                  />
                ) : isDocumentExtension(
                    selectedImage.name.toLowerCase().split('.').pop()
                  ) ? (
                  <a
                    href={URL.createObjectURL(selectedImage)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="36"
                      height="48"
                      className={css.docInChat}
                      viewBox="0 0 36 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M21 12.75V0H2.25C1.00312 0 0 1.00312 0 2.25V45.75C0 46.9969 1.00312 48 2.25 48H33.75C34.9969 48 36 46.9969 36 45.75V15H23.25C22.0125 15 21 13.9875 21 12.75ZM28.1672 32.565L19.1278 41.5369C18.5044 42.1566 17.4975 42.1566 16.8741 41.5369L7.83469 32.565C6.88313 31.6209 7.55062 30 8.88937 30H15V22.5C15 21.6712 15.6712 21 16.5 21H19.5C20.3288 21 21 21.6712 21 22.5V30H27.1106C28.4494 30 29.1169 31.6209 28.1672 32.565ZM35.3438 9.84375L26.1656 0.65625C25.7438 0.234375 25.1719 0 24.5719 0H24V12H36V11.4281C36 10.8375 35.7656 10.2656 35.3438 9.84375Z" />
                    </svg>
                  </a>
                ) : (
                  <video
                    className={css.imgUpload}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    controls
                  >
                    <source
                      src={URL.createObjectURL(selectedImage)}
                      type={`video/${selectedImage.name.split('.').pop()}`}
                    />
                  </video>
                )}
              </div>
              <div className={css.imageInfo}>
                <p>{selectedImage.name}</p>
                <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} МБ</p>
                <input
                  className={css.imgInput}
                  type="text"
                  value={imageText}
                  onChange={e => setImageText(e.target.value)}
                  placeholder="Write text to img"
                />
              </div>
              <div className={css.buttons}>
                <SendImgSVG className={css.sendImg} onClick={handleImageSend} />
                <ButtonReplyCloseSVG
                  className={css.svgCloseReply}
                  onClick={handleImageClose}
                />
              </div>
            </div>
          )}

          <div className={css.input_container}>
            <label htmlFor="message" className={css.input_label}>
              <input
                type="text"
                id="message"
                value={editingMessageId ? editedMessage : message}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                placeholder="Write message"
                className={css.input_text}
              />
              <div className={css.containerFlex}>
                {editingMessageId && (
                  <ButtonReplyCloseSVG
                    className={css.svgCloseEdit}
                    onClick={handleCancelEdit}
                  />
                )}
                <label className={css.file_input_label}>
                  <AddFileSVG className={css.add_file_icon} />
                  {selectedFilesCount > 0 && (
                    <span className={css.selected_files_count}>
                      {selectedFilesCount}
                    </span>
                  )}
                  {/* <input type="file" key={selectedFilesCount} accept="image/*" onChange={handleImageChange}  className={css.file_input} /> */}
                  <input
                    type="file"
                    key={selectedFilesCount}
                    onChange={handleImageChange}
                    className={css.file_input}
                  />
                </label>
              </div>
            </label>
            <div className={css.input_container}>
              {editingMessageId ? (
                <ButtonSendMessage
                  className={css.button_send}
                  onClick={handleEditMessageSend}
                />
              ) : (
                <ButtonSendMessage
                  className={css.button_send}
                  onClick={handleChatMessageSend}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        isOpen={isImageModalOpen}
        imageUrl={selectedImageUrl}
        onClose={() => setIsImageModalOpen(false)}
      />
    </div>
  );
};

export default PersonalChat;
