import axios from 'axios';
import AddRoomToTabModal from 'components/Modal/AddRoomToTabModal';
import ChangeIconTabModal from 'components/Modal/ChangeIconTabModal';
import CreateTabModal from 'components/Modal/CreateTabModal';
import DeleteRoomFromTabModal from 'components/Modal/DeleteRoomFromTabModal';
import DeleteTabModal from 'components/Modal/DeleteTabModal';
import LoginModal from 'components/Modal/LoginModal';
import VerificationEmailModal from 'components/Modal/VerificationEmailModal';
import { useCallback, useEffect, useState } from 'react';
import useLoginModal from '../Hooks/useLoginModal';
import { ReactComponent as ToggleMenuTabsSvg } from '../Images/ToggleMenuTabs.svg';
import bgTabs from '../Images/bgTabs.webp';
import { ReactComponent as ChangeIconSvg } from '../Images/changeIcon.svg';
import { ReactComponent as DeleteRoomsSvg } from '../Images/deleteRooms.svg';
import { ReactComponent as DeleteTabSvg } from '../Images/deleteTab.svg';
import { ReactComponent as MoveRoomsSvg } from '../Images/moveRooms.svg';
import { ReactComponent as RenameTabSvg } from '../Images/renameTab.svg';
import { ReactComponent as TabCanselButtonSvg } from '../Images/tabCanselButton.svg';
import { ReactComponent as TabConfirmButtonSvg } from '../Images/tabConfirmButton.svg';
import { useAuth } from '../LoginForm/AuthContext';
import RoomList from '../RoomList/RoomList';
import css from './Tabs.module.css';
// import tabsIcons from './TabsIcons';
import { icons } from './TabsIcons';

const Tabs = () => {
  const defaultTabs = [
    { id: -1, name_tab: 'All rooms', image_tab: 'AllRooms' },
    { id: -2, name_tab: 'Secret rooms', image_tab: 'SecretRooms' },
    { id: -3, name_tab: 'My rooms', image_tab: 'MyRooms' },
  ];

  const [tabs, setTabs] = useState(defaultTabs);
  const [rooms, setRooms] = useState([]);
  // const [npm startId, setSelectedTabId] = useState(null);
  const { authToken } = useAuth();
  const [isCreateTabModalOpen, setIsCreateTabModalOpen] = useState(false);
  const [isMenuTabsOpen, setIsMenuTabsOpen] = useState(false);
  const [newTabName, setNewTabName] = useState('');
  const [isChangeIconModalOpen, setIsChangeIconModalOpen] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(-1); // айди текущей табы
  const [targetTabId, setTargetTabId] = useState(null); // айди выбранной табы для перемещения комнат
  const [currentTabIcon, setCurrentTabIcon] = useState(null);
  // const [currentTabName, setCurrentTabName] = useState(null);
  // const [isAllRoomsTabSelected, setIsAllRoomsTabSelected] = useState(true);
  // const [isSecretTabSelected, setIsSecretTabSelected] = useState(false);
  // const [isMyRoomsTabSelected, setIsMyRoomsTabSelected] = useState(false);
  const {
    isLoginModalOpen,
    // openLoginModal,
    closeLoginModal,
    handleRegistrationSuccess,
    showVerificationModal,
    setShowVerificationModal,
  } = useLoginModal();
  const [isMoveTabOpen, setIsMoveTabOpen] = useState(false);
  const [isMoveTabOpenDelete, setIsMoveTabOpenDelete] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [buttonAction, setButtonAction] = useState(null);
  const [isDeleteRoomModalOpen, setIsDeleteRoomModalOpen] = useState(false);
  const [isDeleteTabModalOpen, setIsDeleteTabModalOpen] = useState(false);
  const [isAddRoomToTabModalOpen, setIsAddRoomToTabModalOpen] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // useEffect(() => {
  //   if (authToken) {
  //     const fetchTabs = () => {
  //       axios
  //         .get(`${apiBaseUrl}/api/tabs/`, {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //             'Content-Type': 'application/json',
  //             Accept: 'application/json',
  //           },
  //         })
  //         .then(response => {
  //           setTabs(Object.values(response.data));
  //           console.log(Object.values(response.data));
  //         })
  //         .catch(error => {
  //           console.error('Error fetching tabs:', error);
  //         });
  //     };
  //     fetchTabs();
  //   } else {
  //     setTabs([]);
  //     setSelectedTab('Web');
  //     loadRooms();
  //   }
  // }, [authToken]);

  useEffect(() => {
    if (authToken) {
      const fetchTabs = () => {
        axios
          .get(`${apiBaseUrl}/api/tabs/`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
          .then(response => {
            const fetchedTabs = Object.values(response.data);

            const allTabs = [...fetchedTabs, ...defaultTabs].sort((a, b) => {
              if (a.id < 0 && b.id < 0) {
                return a.id - b.id;
              }
              if (a.id > 0 && b.id > 0) {
                return b.id - a.id;
              }
              return a.id > 0 ? -1 : 1;
            });

            setTabs(allTabs);
          })
          .catch(error => {
            console.error('Error fetching tabs:', error);
          });
      };
      fetchTabs();
    } else {
      const noAuthTab = {
        id: -1,
        name_tab: 'All rooms',
        image_tab: 'AllRooms',
      };
      setTabs([noAuthTab]);
      setCurrentTabId(-1);
      loadRooms();
    }
  }, [authToken]);

  const fetchRooms = useCallback(
    id => {
      console.log(id);
      if (id === -1) {
        loadRooms();
        return;
      }
      if (!authToken) {
        console.log('No auth token available');
        return;
      }
      if (id === -2) {
        fetchSecretRooms();
        return;
      }
      if (id === -3) {
        fetchMyRooms();
        return;
      }
      axios
        .get(`${apiBaseUrl}/api/tabs/${id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(response => {
          setRooms(response.data || []);
          const selectedTabData = tabs.find(tab => tab.id === id);
          setNewTabName(selectedTabData?.name_tab);
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching rooms:', error);
        });
    },
    [authToken, tabs]
  );

  const loadRooms = () => {
    axios
      .get(`${apiBaseUrl}/api/rooms/`)
      .then(response => {
        setRooms(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Ошибка при загрузке списка комнат:', error);
      });
  };

  useEffect(() => {
    loadRooms();
  }, []);

  const closeCreateTabModal = e => {
    e?.stopPropagation();
    setIsCreateTabModalOpen(false);
  };

  // useEffect(() => {
  //   const defaultTab = tabs.find(tab => tab.id === -1); // All Rooms tab id
  //   if (defaultTab) {
  //     fetchRooms(defaultTab.id);
  //   }
  // }, [fetchRooms, tabs]);

  const fetchSecretRooms = () => {
    axios
      .get(`${apiBaseUrl}/api/secret/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
        },
      })
      .then(response => {
        setRooms(response.data || []);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching secret rooms:', error);
      });
  };

  const handleSelectTab = id => {
    setRooms([]);
    console.log(id);
    setCurrentTabId(id);
    const selectedTabData = tabs.find(tab => tab.id === id);
    console.log(selectedTabData);
    setCurrentTabIcon(selectedTabData?.image_tab);
    fetchRooms(id);
    setIsMenuTabsOpen(false);
    setIsMoveTabOpen(false);
    setIsMoveTabOpenDelete(false);
    setSelectedRooms([]);
    setButtonAction(null);
  };

  const fetchMyRooms = () => {
    axios
      .get(`${apiBaseUrl}/api/user_rooms/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
        },
      })
      .then(response => {
        setRooms(response.data || []);
        // console.log('User Rooms:', response.data);
      })
      .catch(error => {
        console.error('Error fetching user rooms:', error);
      });
  };

  const handleRoomCreated = newRoom => {
    setRooms(prevRooms => [...prevRooms, newRoom]);
  };

  const handleCreateTab = newTab => {
    setTabs([...tabs, newTab]);
  };

  const handleRenameTab = () => {
    const selectedTabData = tabs.find(tab => tab.id === currentTabId);
    if (!selectedTabData || !newTabName) {
      console.error('No tab selected or new tab name is empty');
      return;
    }
    axios
      .put(
        `${apiBaseUrl}/api/tabs/?id=${currentTabId}`,
        {
          name_tab: newTabName,
          image_tab: selectedTabData?.image_tab,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then(response => {
        console.log('Tab renamed:', response.data);
        setTabs(prevTabs =>
          prevTabs.map(tab =>
            tab.id === currentTabId ? { ...tab, name_tab: newTabName } : tab
          )
        );
        // setCurrentTabName(newTabName);
        setButtonAction(null);
      })
      .catch(error => {
        console.error('Error renaming tab:', error);
      });
  };

  const handleDeleteTab = () => {
    // const selectedTabData = tabs.find(tab => tab.id === currentTabId);
    if (!currentTabId) {
      console.error('No tab selected for deletion');
      return;
    }
    axios
      .delete(`${apiBaseUrl}/api/tabs/?id=${currentTabId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(response => {
        console.log('Tab deleted:', response.data);
        setTabs(prevTabs => prevTabs.filter(tab => tab.id !== currentTabId));
        // setSelectedTab('All rooms');
        setNewTabName('');
        setButtonAction(null);
        setIsDeleteTabModalOpen(false);
        setCurrentTabId(null);
        setCurrentTabIcon(null);
        // setIsAllRoomsTabSelected(true);
        // loadRooms();
        setIsMenuTabsOpen(false);
        handleSelectTab(-1);
      })
      .catch(error => {
        console.error('Error deleting tab:', error);
      });
  };

  const openChangeIconModal = () => {
    setIsChangeIconModalOpen(true);
    // console.log(selectedTab);
    console.log(currentTabId);
    console.log(currentTabIcon);
  };

  const closeChangeIconModal = () => {
    setIsChangeIconModalOpen(false);
    // setCurrentTabId(null);
  };

  const toggleMenu = () => {
    setIsMenuTabsOpen(!isMenuTabsOpen);
  };

  const handleTargetTabClick = tabId => {
    setTargetTabId(tabId);
    console.log('Clicked tab ID:', tabId);
  };

  const handleMoveRooms = () => {
    if (!targetTabId || selectedRooms.length === 0) {
      console.error('No target tab selected or no rooms selected');
      return;
    }

    const data = selectedRooms;

    axios
      .post(`${apiBaseUrl}/api/tabs/add-room-to-tab/${targetTabId}`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(response => {
        console.log('Rooms moved:', response.data);
        setIsAddRoomToTabModalOpen(true);
        fetchRooms(currentTabId);
      })
      .catch(error => {
        console.error('Error moving rooms:', error);
      });
  };

  const handleRemoveRoomsFromTab = () => {
    if (!selectedRooms || selectedRooms.length === 0) {
      console.error('No rooms selected for removal');
      return;
    }

    const data = selectedRooms;

    axios
      .delete(`${apiBaseUrl}/api/tabs/delete-room-in-tab/${currentTabId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: data,
      })
      .then(response => {
        console.log('Room removed from tab:', response.data);
        setButtonAction(null);
        setIsDeleteRoomModalOpen(false);
        fetchRooms(currentTabId);
      })
      .catch(error => {
        console.error('Error removing room from tab:', error);
      });
  };

  const handleActionButtonClick = action => {
    setButtonAction(action);
  };

  const handleConfirmAction = () => {
    switch (buttonAction) {
      case 'rename':
        handleRenameTab();
        break;
      case 'deleteTab':
        setIsDeleteTabModalOpen(true);
        // handleDeleteTab();
        break;
      case 'move':
        handleMoveRooms();
        break;
      case 'removeRooms':
        if (!selectedRooms || selectedRooms.length === 0) {
          alert('No rooms selected for removal');
          return;
        }
        setIsDeleteRoomModalOpen(true);
        break;

      default:
        console.error('Unknown action:', buttonAction);
    }
  };

  const handleCancelAction = () => {
    setSelectedRooms([]);
    setButtonAction(null);
    setIsMoveTabOpen(false);
    setIsMoveTabOpenDelete(false);
  };

  return (
    <div className={css.tabsContainer}>
      <div className={css.tabsContainerTitle}>
        <div className={css.tabsFlex}>
          {authToken && currentTabId !== -2 && currentTabId !== -3 && (
            <button
              className={`${css.menuButton} ${
                isMenuTabsOpen ? css.menuButtonOpen : ''
              }`}
              onClick={toggleMenu}
            >
              <ToggleMenuTabsSvg />
            </button>
          )}

          <h2>{tabs.find(tab => tab.id === currentTabId)?.name_tab}</h2>
        </div>
        <ul className={css.list_tabs}>
          {tabs.map(tab => {
            const IconComponent = icons[tab.image_tab]?.component;
            return (
              <li
                key={tab.id}
                className={`${css.item_tabs} ${
                  currentTabId === tab.id ? css.selected : ''
                }`}
                onClick={() => handleSelectTab(tab.id)}
              >
                {IconComponent ? (
                  <IconComponent className={css.tab_icon} />
                ) : (
                  tab.name_tab
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={`${css.flex} ${isMenuTabsOpen ? css.roomListShifted : ''}`}
      >
        <div
          className={`${css.menuTabs_container} ${
            isMenuTabsOpen ? css.menuTabs_containerOpen : ''
          }`}
        >
          <div className={css.container}>
            <h2 className={css.menu_title}>Tab settings</h2>
            {currentTabId && (
              <ul className={css.menu_list}>
                {currentTabId !== -1 && (
                  <>
                    <li>
                      <label className={css.menu_subtitle}>
                        <p className={css.text}>Rename the tab</p>
                        <RenameTabSvg />
                      </label>
                      <input
                        type="text"
                        className={css.menu_input}
                        value={newTabName}
                        onChange={e => setNewTabName(e.target.value)}
                        placeholder="Enter new tab name"
                        onClick={() => handleActionButtonClick('rename')}
                      />
                    </li>

                    <li
                      className={css.menu_subtitle}
                      onClick={() => handleActionButtonClick('deleteTab')}
                    >
                      <p className={css.text}>Delete the tab</p>
                      <DeleteTabSvg style={{ height: '30px' }} />
                    </li>

                    <li
                      className={css.menu_subtitle}
                      onClick={openChangeIconModal}
                    >
                      <p className={css.text}>Change the icon</p>
                      <ChangeIconSvg />
                    </li>
                  </>
                )}

                {rooms.length > 0 && (
                  <li
                    className={css.menu_subtitle}
                    onClick={() => {
                      handleActionButtonClick('move');
                      setIsMoveTabOpen(true);
                    }}
                  >
                    <p className={css.text}>Move rooms to...</p>
                    <MoveRoomsSvg style={{ height: '30px' }} />
                  </li>
                )}

                {isMoveTabOpen && (
                  <ul>
                    {tabs
                      .filter(
                        tab =>
                          tab.id !== currentTabId &&
                          ![-1, -2, -3].includes(tab.id)
                      )
                      .map(tab => (
                        <li
                          key={tab.id}
                          onClick={() => handleTargetTabClick(tab.id)}
                          className={`${css.menu_subtitle2} ${
                            targetTabId === tab.id ? css.highlightedTab : ''
                          }`}
                        >
                          {tab.name_tab}
                        </li>
                      ))}
                  </ul>
                )}

                {rooms.length > 0 && currentTabId !== -1 && (
                  <li
                    className={css.menu_subtitle}
                    onClick={() => {
                      handleActionButtonClick('removeRooms');
                      setIsMoveTabOpenDelete(true);
                    }}
                  >
                    <p className={css.text}>Delete rooms</p>
                    <DeleteRoomsSvg style={{ height: '30px' }} />
                  </li>
                )}
              </ul>
            )}
          </div>

          {buttonAction && (
            <div className={css.confirmCancelButtons}>
              <TabConfirmButtonSvg
                className={css.confirmButton}
                onClick={handleConfirmAction}
              />
              <TabCanselButtonSvg
                className={css.cancelButton}
                onClick={handleCancelAction}
              />
            </div>
          )}
        </div>
        {rooms.length === 0 ? (
          <div className={css.emptyTabImageContainer}>
            <img
              src={bgTabs}
              alt="No rooms available"
              className={css.emptyTabImage}
            />
          </div>
        ) : (
          <RoomList
            rooms={rooms}
            onRoomCreated={handleRoomCreated}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            isMoveTabOpen={isMoveTabOpen}
            isMoveTabOpenDelete={isMoveTabOpenDelete}
          />
        )}
      </div>
      <CreateTabModal
        isOpen={isCreateTabModalOpen}
        onClose={closeCreateTabModal}
        onCreateTab={handleCreateTab}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onRegistrationSuccess={handleRegistrationSuccess}
      />
      <VerificationEmailModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
      />
      <ChangeIconTabModal
        isOpen={isChangeIconModalOpen}
        onClose={closeChangeIconModal}
        authToken={authToken}
        currentTabId={currentTabId}
        icons={icons}
        tabs={tabs}
        setTabs={setTabs}
      />
      <DeleteRoomFromTabModal
        isOpen={isDeleteRoomModalOpen}
        onClose={() => {
          setIsDeleteRoomModalOpen(false);
          setSelectedRooms([]);
          setButtonAction(null);
          setIsMoveTabOpenDelete(false);
        }}
        onConfirmDelete={handleRemoveRoomsFromTab}
      />
      <AddRoomToTabModal
        isOpen={isAddRoomToTabModalOpen}
        onClose={() => {
          setIsAddRoomToTabModalOpen(false);
          setSelectedRooms([]);
          setButtonAction(null);
          setIsMoveTabOpenDelete(false);
        }}
      />
      <DeleteTabModal
        isOpen={isDeleteTabModalOpen}
        onClose={() => {
          setIsDeleteTabModalOpen(false);
          setButtonAction(null);
        }}
        onConfirmDelete={handleDeleteTab}
        tabId={currentTabId}
      />
    </div>
  );
};

export default Tabs;
