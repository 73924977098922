import axios from 'axios';
import useDebounce from 'components/Hooks/useDebounce';
import DeleteAccountModal from 'components/Modal/DeleteAccountModal';
import ErrorModal from 'components/Modal/ErrorModal';
import PasswordChangeSuccesfulModal from 'components/Modal/PasswordChangeSuccesfulModal';
import VerificationEmailModal from 'components/Modal/VerificationEmailModal';
import ResetPasswordForm from 'components/resetPasswordForm/resetPasswordForm';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import useLoginModal from '../../components/Hooks/useLoginModal';
import { ReactComponent as AccDeleteSVG } from '../../components/Images/AccDelete.svg';
import { ReactComponent as AccLogOutSVG } from '../../components/Images/AccLogOut.svg';
import { ReactComponent as AccSettingsSVG } from '../../components/Images/AccSettings.svg';
import { ReactComponent as AddPhotoSVG } from '../../components/Images/AddPhoto.svg';
import DefaultAvatar from '../../components/Images/defaultAvatar.svg';
import { useAuth } from '../../components/LoginForm/AuthContext';
import LogoutModal from '../../components/Modal/LogoutModal';
import css from './AccountPage.module.css';
import { useAvatar } from './AvatarContext';

const AccountPage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { setAvatarUrl } = useAvatar();
  const user_avatar = localStorage.getItem('avatar');
  const { authToken } = useAuth();
  const [userNameValue, setUserNameValue] = useState('');
  const [isUserNameUnique, setIsUserNameUnique] = useState(true);
  const debouncedUserName = useDebounce(userNameValue, 1500);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { showVerificationModal, setShowVerificationModal } = useLoginModal();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const checkVerificationStatus = () => {
      const headers = {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
      };

      axios
        .get(`${apiBaseUrl}/api/users/me/`, { headers })
        .then(response => {
          setIsVerified(response.data.verified);
        })
        .catch(error => {
          console.error('Error checking user verification:', error);
        });
    };

    checkVerificationStatus();
  }, [authToken, apiBaseUrl]);

  const openLogoutModal = () => setIsLogoutModalOpen(true);
  const closeLogoutModal = () => setIsLogoutModalOpen(false);

  const openSuccessModal = () => setIsPasswordChangeSuccessful(true);
  const closeSuccessModal = () => setIsPasswordChangeSuccessful(false);

  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => setIsErrorModalOpen(false);

  const openDeleteAccountModal = () => setIsDeleteAccountModalOpen(true);
  const closeDeleteAccountModal = () => setIsDeleteAccountModalOpen(false);

  useEffect(() => {
    const storedUserName = localStorage.getItem('user_name');
    if (storedUserName) {
      setUserNameValue(storedUserName);
    }
  }, []);

  const handleAvatarChange = event => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .put(`${apiBaseUrl}/api/users/v2/avatar`, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        })
        .then(response => {
          console.log('Avatar uploaded successfully:', response.data);
          const reader = new FileReader();
          reader.onloadend = () => {
            localStorage.setItem('avatar', reader.result);
            setAvatarUrl(reader.result);
          };
          reader.readAsDataURL(file);
        })
        .catch(error => {
          console.error('Error uploading avatar:', error);
        });
    }
  };

  useEffect(() => {
    console.log('Debounced user name:', debouncedUserName);
    const checkUserNameUnique = async () => {
      if (!debouncedUserName) return;
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/users/audit/${debouncedUserName}`
        );
        setIsUserNameUnique(response.status === 204);
      } catch (error) {
        console.error('Error checking username uniqueness:', error);
      }
    };
    checkUserNameUnique();
  }, [debouncedUserName]);

  const validationSchema = yup.object().shape({
    user_name: yup
      .string()
      .required('Username is required')
      .matches(
        /^[a-zA-Z\u0430-\u044F\u0410-\u042F\u0456\u0406\u0457\u0407\u0491\u0490\u0454\u0404\u04E7\u04E6 ()_.]+$/,
        'Please input only A-Z, numbers not allowd'
      )
      .test(
        'checkUsernameUnique',
        'Username already exists',
        () => isUserNameUnique
      ),
  });

  const handleUsernameSubmit = (values, { setSubmitting }) => {
    axios
      .put(
        `${apiBaseUrl}/api/users/v2/username`,
        `user_name=${values.user_name}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
          },
        }
      )
      .then(response => {
        console.log('Username updated successfully:', response.data);
        localStorage.setItem('user_name', values.user_name);
        setSubmitting(false);
      })
      .catch(error => {
        console.error('Error updating username:', error);
        setSubmitting(false);
        openErrorModal();
      });
  };

  const handlePasswordChangeSubmit = (values, { setSubmitting, resetForm }) => {
    const requestData = {
      old_password: values.currentPassword,
      new_password: values.newPassword,
    };

    axios
      .put(`${apiBaseUrl}/api/manipulation/password`, requestData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(response => {
        console.log('Password updated successfully:', response.data);
        openSuccessModal();
        resetForm();
        setSubmitting(false);
      })
      .catch(error => {
        console.error('Error updating password:', error);
        openErrorModal();
        setSubmitting(false);
      });
  };

  const handleDeleteAccount = async password => {
    try {
      await axios.delete(`${apiBaseUrl}/api/users/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { password },
      });
      navigate('/');
    } catch (error) {
      console.error('Failed to delete account:', error);
      openErrorModal();
    }
    closeDeleteAccountModal();
  };

  return (
    <section
      className={`${css.container} ${isSettingsOpen ? css.shiftLeft : ''}`}
    >
      <label className={css.uploadLabel}>
        <input
          type="file"
          accept="image/*"
          className={css.fileInput}
          onChange={handleAvatarChange}
        />
        <div className={css.avatarOverlay}>
          <AddPhotoSVG />
        </div>
        <img
          src={authToken ? user_avatar : DefaultAvatar}
          alt={authToken ? 'User Avatar' : 'Default Avatar'}
          className={css.avatar}
        />
      </label>
      <input
        type="text"
        className={css.fileInput}
        onChange={handleAvatarChange}
      />
      <Formik
        initialValues={{
          user_name: userNameValue,
        }}
        validationSchema={validationSchema}
        onSubmit={handleUsernameSubmit}
      >
        {({ errors, touched, setFieldValue, isSubmitting, isValid }) => (
          <Form className={css.registerForm}>
            <div>
              <label htmlFor="user_name"></label>
              <Field
                className={`${
                  touched.user_name && errors.user_name
                    ? css.isInvalid
                    : touched.user_name
                    ? css.isValid
                    : ''
                } ${css.input}`}
                type="text"
                id="user_name"
                name="user_name"
                placeholder="User"
                value={userNameValue}
                onChange={e => {
                  setUserNameValue(e.target.value);
                  setFieldValue('user_name', e.target.value);
                }}
              />
              <ErrorMessage
                name="user_name"
                component="div"
                className={css.errorMessage}
              />
            </div>
            <div></div>
            {isValid && (
              <button
                className={css.button}
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Approve
              </button>
            )}
          </Form>
        )}
      </Formik>
      {isVerified !== null && (
        <>
          {isVerified ? (
            <p className={css.verified}>Account verified</p>
          ) : (
            <p
              className={css.notVerified}
              onClick={() => setShowVerificationModal(true)}
            >
              You are not verified. Please, complete verification to fully use
              the account.
            </p>
          )}
        </>
      )}

      <ul className={css.settingsList}>
        <li className={css.settingsItem}>
          <button
            className={css.settingsButton}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          >
            <AccSettingsSVG
              className={isSettingsOpen ? css.rotate : css.rotateBack}
            />
            <p>Settings</p>
          </button>
        </li>
        <li className={css.settingsItem}>
          <button className={css.settingsButton} onClick={openLogoutModal}>
            <AccLogOutSVG />
            <p>LogOut</p>
          </button>
        </li>
        <li className={css.settingsItem}>
          <button
            className={css.settingsButton}
            onClick={openDeleteAccountModal}
          >
            <AccDeleteSVG />
            <p>Delete account</p>
          </button>
        </li>
      </ul>
      <LogoutModal isOpen={isLogoutModalOpen} onClose={closeLogoutModal} />
      {isSettingsOpen && (
        <div className={css.newSettingsBlock}>
          <h2>Settings</h2>
          <ResetPasswordForm handleSubmit={handlePasswordChangeSubmit} />
        </div>
      )}
      <PasswordChangeSuccesfulModal
        isOpen={isPasswordChangeSuccessful}
        onClose={closeSuccessModal}
      />
      <ErrorModal isOpen={isErrorModalOpen} onClose={closeErrorModal} />
      <VerificationEmailModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
      />
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={closeDeleteAccountModal}
        onConfirmDelete={handleDeleteAccount}
      />
    </section>
  );
};

export default AccountPage;
