import { useEffect } from 'react';
import imgModalSuccess from '../Images/imgModalSuccess.png';
import { useAuth } from '../LoginForm/AuthContext';
import CustomModal from './CustomModal';
import css from './PasswordChangeSuccesfulModal.module.css';

const PasswordChangeSuccesfulModal = ({ isOpen, onClose }) => {
  const { logout } = useAuth();

  const handleLogout = async () => {
    localStorage.setItem('shouldLogout', 'true');
    await logout();
    onClose();
  };

  useEffect(() => {
    const shouldLogout = localStorage.getItem('shouldLogout');

    if (shouldLogout === 'true') {
      localStorage.removeItem('shouldLogout');
      logout();
    }
  }, [logout]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
      <div className={css.container}>
        <h2 className={css.title}>Password update succesfully</h2>
        <img className={css.imgCenter} src={imgModalSuccess} alt="Letter" />

        <button className={css.button} onClick={handleLogout}>
          Close
        </button>
      </div>
    </CustomModal>
  );
};

export default PasswordChangeSuccesfulModal;
