import { AuthProvider } from 'components/LoginForm/AuthContext';
import 'index.css';
import { Route, Routes } from 'react-router-dom';
import PersonalChat from '../components/PersonalChat/PersonalChat';
import AccountPage from '../pages/AccountPage/AccountPage';
import { AvatarProvider } from '../pages/AccountPage/AvatarContext';
import PersonalChatPage from '../pages/PersonalChatPage/PersonalChatPage';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import RoolsOfTheChat from '../pages/RoolsOfTheChat/RoolsOfTheChat';
import SearchPage from '../pages/SearchPage/SearchPage';
import Chat from './Chat/Chat';
import Header from './Header/Header';
import Main from './Main/Main';
import { MessageProvider } from './SocketNotification/MessageContext';
import { NotificationProvider } from './SocketNotification/NotificationContext';
import SocketNotification from './SocketNotification/SocketNotification';

import Modal from 'react-modal';
import Footer from './Footer/Footer';

Modal.setAppElement('#root');

export const App = () => {
  return (
    <div className="app">
      <AuthProvider>
        <NotificationProvider>
          <MessageProvider>
            <AvatarProvider>
              <Header />
              <SocketNotification />
              <Routes>
                <Route path="/" exact element={<Main />} />
                <Route
                  path="/PersonalChatPage"
                  element={<PersonalChatPage />}
                />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/RoolsOfTheChat" element={<RoolsOfTheChat />} />
                <Route path="/chat/:roomId" element={<Chat />} />
                <Route
                  path="/PersonalChat/:recipient_name"
                  element={<PersonalChat />}
                />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/acc" element={<AccountPage />} />
              </Routes>
              <Footer />
            </AvatarProvider>
          </MessageProvider>
        </NotificationProvider>
      </AuthProvider>
    </div>
  );
};
