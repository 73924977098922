import React, { useState } from 'react';
import css from './CreateRoom.module.css';
import CreateRoomModal from '../Modal/CreateRoomModal';
import CreateRoomImg from 'components/Images/CreateRoomImg.png';
import IconAdd from 'components/Images/IconAdd.svg';

const CreateRoom = ({ onRoomCreated }) => {
  const [isCreateRoomModalOpen, setIsCreateRoomModalOpen] = useState(false);

  const openCreateRoomModal = () => {
    setIsCreateRoomModalOpen(true);
  };

  const closeCreateRoomModal = () => {
    setIsCreateRoomModalOpen(false);
  };

  return (
    <>
      <div className={css.room_item} onClick={openCreateRoomModal}>
        <div className={css.room_container}>
          <img
            src={CreateRoomImg}
            alt="CreateRoomImg"
            className={css.room_img}
          />
          <div className={css.room_add}>
            <img
              src={IconAdd}
              alt="IconAdd"
              className={css.room_imgAdd}
            />
            <p className={css.room_name}>Add room</p>
          </div>
        </div>
        <div className={css.room_description}>
          <div className={css.people_count}>
          </div>
        </div>
      </div>
      <CreateRoomModal
        isOpen={isCreateRoomModalOpen}
        onClose={closeCreateRoomModal}
        onRoomCreated={onRoomCreated}
      />
    </>
  );
};

export default CreateRoom;
