import {
  Add,
  Adjust,
  Biotech,
  Brightness2,
  Build,
  Campaign,
  Casino,
  Create,
  CurrencyBitcoin,
  Diamond,
  DirectionsBus,
  Egg,
  EmojiEvents,
  Extension,
  Favorite,
  Fingerprint,
  Flight,
  Gamepad,
  Hiking,
  Label,
  Language,
  LocalHospital,
  LocalPolice,
  Pets,
  Phone,
  PhoneAndroid,
  RamenDining,
  Restaurant,
  SettingsInputComponent,
  ShoppingCart,
  Skateboarding,
  SmartToy,
  SportsBasketball,
  SportsEsports,
  Toys,
  EmojiEvents as TrophyIcon,
  Waves,
  Whatshot,
  Work,
  WorkOutline,
} from '@mui/icons-material';

export const icons = {
  Aeroplane: { name: 'Aeroplane', component: Flight },
  ATV: { name: 'ATV', component: Toys },
  AudioInputXLR: { name: 'Audio Input XLR', component: SettingsInputComponent },
  AutoFix: { name: 'Auto Fix', component: Build },
  Bandage: { name: 'Bandage', component: LocalHospital },
  Basketball: { name: 'Basketball', component: SportsBasketball },
  MyRooms: { name: 'My rooms', component: Favorite },
  Briefcase: { name: 'Briefcase', component: Work },
  BriefcaseVariant: { name: 'Briefcase Variant', component: WorkOutline },
  Bullhorn: { name: 'Bullhorn', component: Campaign },
  Bus: { name: 'Bus', component: DirectionsBus },
  Cart: { name: 'Cart', component: ShoppingCart },
  CellphoneBasic: { name: 'Cellphone Basic', component: PhoneAndroid },
  Controller: { name: 'Controller', component: Gamepad },
  CreationOutline: { name: 'Creation Outline', component: Create },
  CrossOutline: { name: 'Cross Outline', component: Add },
  'Crown Circle': { name: 'Crown Circle', component: EmojiEvents },
  CrystalBall: { name: 'Crystal Ball', component: SportsEsports },
  CurrencyBTC: { name: 'Currency BTC', component: CurrencyBitcoin },
  DeathStarVariant: { name: 'Death Star Variant', component: Brightness2 },
  DiamondStone: { name: 'Diamond Stone', component: Diamond },
  DiceMultiple: { name: 'Dice Multiple', component: Casino },
  DNA: { name: 'DNA', component: Biotech },
  Dolphin: { name: 'Dolphin', component: Waves },
  Dog: { name: 'Pets', component: Pets },
  Duck: { name: 'Duck', component: Adjust },
  EggFried: { name: 'Egg Fried', component: Egg },
  Ethereum: { name: 'Ethereum', component: CurrencyBitcoin },
  SecretRooms: { name: 'Secret rooms', component: Fingerprint },
  Fire: { name: 'Fire', component: Whatshot },
  FoodTurkey: { name: 'Food Turkey', component: Restaurant },
  Ghost: { name: 'Ghost', component: SportsEsports },
  Hiking: { name: 'Hiking', component: Hiking },
  Noodles: { name: 'Noodles', component: RamenDining },
  PhoneClassic: { name: 'Phone Classic', component: Phone },
  PoliceBadge: { name: 'Police Badge', component: LocalPolice },
  Puzzle: { name: 'Puzzle', component: Extension },
  Robot: { name: 'Robot', component: SmartToy },
  Skateboarding: { name: 'Skateboarding', component: Skateboarding },
  TagMultiple: { name: 'Tag Multiple', component: Label },
  AllRooms: { name: 'All Rooms', component: Language },
  Trophy: { name: 'Trophy', component: TrophyIcon },
};
