import axios from 'axios';
import { icons } from 'components/Tabs/TabsIcons';
import { useState } from 'react';
import { useAuth } from '../LoginForm/AuthContext';
import css from './CreateTabModal.module.css';
import CustomModal from './CustomModal';

const CreateTabModal = ({ isOpen, onClose, onCreateTab }) => {
  const [selectedIcon, setSelectedIcon] = useState('');
  const [tabName, setTabName] = useState('');
  const { authToken } = useAuth();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleCreateTab = async () => {
    try {
      const requestData = {
        name_tab: tabName,
        image_tab: selectedIcon,
      };

      const response = await axios.post(
        `${apiBaseUrl}/api/tabs/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      console.log('Tab created:', response.data);
      onCreateTab(response.data);
      setTabName('');
      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error creating tab:', error);
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
      <div className={css.createRoomContainer}>
        <h2 className={css.title}>Add a new Tab</h2>
        <label className={css.text}>
          Name of the chat Tab*
          <input
            className={css.input}
            type="text"
            placeholder="Tab Name"
            value={tabName}
            onChange={e => setTabName(e.target.value)}
          />
        </label>
        <div>
          <label className={css.text1}>Choose an icon for the Tab*</label>
          <div className={css.iconContainer}>
            {Object.keys(icons).map(iconName => {
              const IconComponent = icons[iconName].component;
              return (
                <div
                  key={iconName}
                  className={`${css.iconWrapper} ${
                    selectedIcon === iconName ? css.selected : ''
                  }`}
                  onClick={() => setSelectedIcon(iconName)}
                >
                  <IconComponent />
                </div>
              );
            })}
          </div>
        </div>
        <div className={css.center}>
          <button className={css.button} onClick={handleCreateTab}>
            Approve
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

//   return (
//     <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
//       <div className={css.createRoomContainer}>
//         <h2 className={css.title}>Add a new Tab</h2>
//         <label className={css.text}>
//           Name of the chat Tab*
//           <input
//             className={css.input}
//             type="text"
//             placeholder="Tab Name"
//             value={tabName}
//             onChange={e => setTabName(e.target.value)}
//           />
//         </label>
//         <div>
//           <label className={css.text1}>Choose an icon for the Tab*</label>
//           <div className={css.iconContainer}>
//             {Object.keys(tabsIcons).map(iconName => {
//               const IconComponent = tabsIcons[iconName];
//               return (
//                 <div
//                   key={iconName}
//                   className={`${css.iconWrapper} ${
//                     selectedIcon === iconName ? css.selected : ''
//                   }`}
//                   onClick={() => setSelectedIcon(iconName)}
//                 >
//                   <IconComponent />
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         <div className={css.center}>
//           <button className={css.button} onClick={handleCreateTab}>
//             Approve
//           </button>
//         </div>
//       </div>
//     </CustomModal>
//   );
// };

export default CreateTabModal;
